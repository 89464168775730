import Navbar from "../../../Navbar"

export default function KnowledgeApp(){
    return (
        <>
            <Navbar />
            <h1>Knowledge App</h1>
            <p>Coming soon...</p>
        </>
    )
}